import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from "../store";

const RedirectBasedOnAuth: React.FC = () => {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const bearerToken = localStorage.getItem('Bearer');
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated && bearerToken) {
            navigate('/assistance', { replace: true });
        } else {
            navigate('/auth/login', { replace: true });
        }
    }, [isAuthenticated, bearerToken, navigate]);

    return null;
};

export default RedirectBasedOnAuth;
