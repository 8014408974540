import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {RootState} from '../../../store';
import {clearSelectedItems, setSelectedTools} from '../../../Slice/SelectedItems/SelectedItemsSlice';
import '../Step2Styles/projectUpdateItems.css';
import IToolsItemType from "../../../Interfaces/ProjectInterfaces/IToolsItemType";
import {getVendorLogo, Vendor} from "../../../Enums/VendorEnum";

interface ToolItemTypeListProps {
    toolsItemTypes: IToolsItemType[];
    viewModeTools: 'Recommended' | 'Minimum';
}

const ToolsItemTypeList: React.FC<ToolItemTypeListProps & PropsFromRedux> = ({
                                                                                 toolsItemTypes,
                                                                                 setSelectedTools,
                                                                                 viewModeTools
                                                                             }) => {
    const [selectedItems, setSelectedItemsState] = useState<{ [key: string]: boolean }>({});
    const [totalPrice, setTotalPrice] = useState<number>(0);

    useEffect(() => {
        setSelectedItemsState({});
        setTotalPrice(0);

        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');

        Object.keys(storedSelectedFragments).forEach(fragmentKey => {
            if (storedSelectedFragments[fragmentKey].ToolsItemTypes) {
                delete storedSelectedFragments[fragmentKey].ToolsItemTypes;
            }
        });

        localStorage.setItem('selectedFragments', JSON.stringify(storedSelectedFragments));
    }, [viewModeTools]);

    useEffect(() => {
        syncSelectionsWithLocalStorage(toolsItemTypes);
    }, [toolsItemTypes,viewModeTools]);

    const mergeItemTypes = (items: IToolsItemType[]) => {
        const mergedItemsMap: { [key: number]: IToolsItemType } = {};

        items.forEach(item => {
            const itemTypeId = item.item_type.id;

            if (mergedItemsMap[itemTypeId]) {
                const existingItemType = mergedItemsMap[itemTypeId];

                item.item_type.items?.forEach(toolItem => {
                    const existingToolItem = existingItemType.item_type.items?.find(ti => ti.id === toolItem.id);

                    if (existingToolItem) {
                        existingToolItem.qty = viewModeTools === 'Minimum'
                            ? Math.max(existingToolItem.qty, toolItem.qty)
                            : existingToolItem.qty + toolItem.qty;

                        existingToolItem.ordered_qty = viewModeTools === 'Minimum'
                            ? Math.max(existingToolItem.ordered_qty ?? 0, toolItem.ordered_qty ?? 0)
                            : (existingToolItem.ordered_qty ?? 0) + (toolItem.ordered_qty ?? 0);
                    } else {
                        existingItemType.item_type.items?.push({ ...toolItem });
                    }
                });
            } else {
                mergedItemsMap[itemTypeId] = JSON.parse(JSON.stringify(item));
            }
        });

        return Object.values(mergedItemsMap);
    };

    const syncSelectionsWithLocalStorage = (items: IToolsItemType[]) => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const updatedToolSelections: { [key: string]: boolean } = {};

        items.forEach(item => {
            const fragmentKey = `${item.fragment_id}-${item.fragment_index}-${item.template_index}`;
            const toolKey = `${item.item_type.id}-${item.clone_id || item.item_type_index}-${item.fragment_index}`;

            if (storedSelectedFragments[fragmentKey]?.ToolsItemTypes?.[toolKey]) {
                item.item_type.items?.forEach(toolItem => {
                    const itemKey = `${toolKey}-${toolItem.id}`;
                    if (storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey].items.some((i: any) => i.id === toolItem.id)) {
                        updatedToolSelections[itemKey] = true;
                    }
                });
            }
        });

        setSelectedItemsState(updatedToolSelections);
    };

    const saveToLocalStorage = (item: IToolsItemType, toolItem: any, toolsItemTypes: IToolsItemType[]) => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');

        toolsItemTypes.forEach((originalItem) => {
            if (originalItem?.item_type?.id === item?.item_type?.id) {
                originalItem?.item_type?.items?.forEach((originalToolItem) => {
                    if (
                        originalToolItem?.id === toolItem?.id &&
                        (viewModeTools === 'Recommended' ||
                            (viewModeTools === 'Minimum' &&
                                originalToolItem?.qty === toolItem?.qty))
                    ) {
                        const fragmentKey = `${originalItem?.fragment_id}-${originalItem?.fragment_index}-${originalItem?.template_index}`;

                        if (!storedSelectedFragments[fragmentKey]) {
                            storedSelectedFragments[fragmentKey] = { items: {}, LaborItemTypes: {}, ToolsItemTypes: {} };
                        }

                        const toolKey = `${originalItem?.item_type?.id}-${originalItem?.clone_id || originalItem?.item_type_index}-${originalItem?.fragment_index}`;

                        if (!storedSelectedFragments[fragmentKey].ToolsItemTypes) {
                            storedSelectedFragments[fragmentKey].ToolsItemTypes = {};
                        }

                        if (!storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey]) {
                            storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey] = {
                                id: originalItem?.item_type?.id,
                                qty: originalItem?.qty || 0,
                                tool: true,
                                items: [],
                                labor: originalItem?.item_type?.labor || false,
                                title: originalItem?.item_type?.title || '',
                                percentage: originalItem?.item_type?.percentage || 0,
                                ordered_qty: 0,
                                originalIndex: originalItem?.item_type_index,
                                clone_id: originalItem?.clone_id || originalItem?.item_type_index,
                                payload: {
                                    template_index: originalItem?.template_index,
                                    fragment_index: originalItem?.fragment_index,
                                    item_type_index: originalItem?.item_type_index,
                                    template_id: originalItem?.template_id,
                                    fragment_id: originalItem?.fragment_id,
                                    item_type_id: originalItem?.item_type?.id,
                                    item_ids: []
                                }
                            };
                        }

                        const selectedToolType = storedSelectedFragments[fragmentKey]?.ToolsItemTypes?.[toolKey];

                        selectedToolType?.items?.push({
                            id: originalToolItem?.id,
                            qty: originalToolItem?.qty || 0,
                            unit: originalToolItem?.unit || '',
                            price: originalToolItem?.price || 0,
                            title: originalToolItem?.title || '',
                            ordered_qty: originalToolItem?.ordered_qty || 0,
                            created_at: originalToolItem?.created_at || '',
                            updated_at: originalToolItem?.updated_at || '',
                            payload: {
                                template_index: originalItem?.template_index,
                                fragment_index: originalItem?.fragment_index,
                                item_type_index: originalItem?.item_type_index,
                                template_id: originalItem?.template_id,
                                fragment_id: originalItem?.fragment_id,
                                item_type_id: originalItem?.item_type?.id,
                                item_ids: [originalToolItem?.id]
                            }
                        });

                        selectedToolType.ordered_qty += originalToolItem?.ordered_qty || 0;
                        selectedToolType.payload.item_ids.push(originalToolItem?.id);
                    }
                });
            }
        });

        localStorage.setItem('selectedFragments', JSON.stringify(storedSelectedFragments));
    };

    const removeFromLocalStorage = (item: IToolsItemType, toolItem: any, toolsItemTypes: IToolsItemType[]) => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');

        toolsItemTypes.forEach((originalItem) => {
            if (originalItem.item_type.id === item.item_type.id) {
                originalItem.item_type.items?.forEach((originalToolItem) => {
                    if (originalToolItem.id === toolItem.id) {
                        const fragmentKey = `${originalItem.fragment_id}-${originalItem.fragment_index}-${originalItem.template_index}`;
                        const toolKey = `${originalItem.item_type_id}-${originalItem.clone_id || originalItem.item_type_index}-${originalItem.fragment_index}`;

                        const selectedToolType = storedSelectedFragments[fragmentKey]?.ToolsItemTypes?.[toolKey];

                        if (selectedToolType) {
                            selectedToolType.items = selectedToolType.items.filter((i: any) => i.id !== originalToolItem.id);

                            if (selectedToolType.items.length === 0) {
                                delete storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey];
                            }
                            if (!storedSelectedFragments[fragmentKey].ToolsItemTypes) {
                                storedSelectedFragments[fragmentKey].ToolsItemTypes = {};
                            }

                            if (!storedSelectedFragments[fragmentKey].items) {
                                storedSelectedFragments[fragmentKey].items = {};
                            }

                            if (!storedSelectedFragments[fragmentKey].LaborItemTypes) {
                                storedSelectedFragments[fragmentKey].LaborItemTypes = {};
                            }
                        }
                    }
                });
            }
        });

        localStorage.setItem('selectedFragments', JSON.stringify(storedSelectedFragments));
    };


    const handleCheckboxChange = (toolKey: string, item: IToolsItemType, toolItem: any) => {
        const isSelected = !selectedItems[toolKey];
        const updatedSelections = { ...selectedItems, [toolKey]: isSelected };
        setSelectedItemsState(updatedSelections);

        if (isSelected) {
            saveToLocalStorage(item, toolItem, toolsItemTypes);
        } else {
            removeFromLocalStorage(item, toolItem, toolsItemTypes);
        }

        setSelectedTools(Date.now());
    };


    const calculateTotalPrice = (items: IToolsItemType[], selected: { [key: string]: boolean }, viewModeTools: 'Recommended' | 'Minimum') => {
        let totalPrice = 0;

        items.forEach(item => {
            const toolKey = `${item.item_type.id}-${item.clone_id || item.item_type_index}-${item.fragment_index}`;

            item.item_type.items?.forEach(toolItem => {
                const itemKey = `${toolKey}-${toolItem.id}`;
                if (selected[itemKey]) {
                    const price = parseFloat(String(toolItem.price)) || 0;
                    let qty = toolItem.qty;
                    if (viewModeTools === 'Minimum') {
                        qty = toolItem.qty;
                    } else {
                        qty = items.reduce((sum, currentItem) => {
                            const combinedToolItem = currentItem.item_type.items?.find(i => i.id === toolItem.id);
                            return sum + (combinedToolItem ? combinedToolItem.qty : 0);
                        }, 0);
                    }
                    const difTotalQty = qty - toolItem.ordered_qty;
                    const priceTotal = difTotalQty > 0 ? price * difTotalQty : 0;
                    totalPrice += priceTotal;
                }
            });
        });

        return totalPrice;
    };
    const mergedItemTypes = mergeItemTypes(toolsItemTypes);

    useEffect(() => {
        const calculatedPrice = calculateTotalPrice(mergedItemTypes, selectedItems, viewModeTools);
        setTotalPrice(calculatedPrice);
        setSelectedTools(Date.now());
    }, [selectedItems, mergedItemTypes, setSelectedTools, viewModeTools]);

    return (
        <div>
            {mergedItemTypes.map((itemType) => {
                const toolKey = `${itemType.item_type.id}-${itemType.clone_id || itemType.item_type_index}-${itemType.fragment_index}`;
                const title = itemType.item_type.title;
                const totalQty = viewModeTools === 'Recommended'
                    ? toolsItemTypes
                        .filter((it) => it.item_type.id === itemType.item_type.id)
                        .reduce((sum, it) => sum + it.qty, 0)
                    : Math.max(
                        ...toolsItemTypes
                            .filter((it) => it.item_type.id === itemType.item_type.id)
                            .map((it) => it.qty)
                    );

                return (
                    <div key={toolKey}>
                        <p className="item-type-list-label">{title}</p>
                        {itemType.item_type.items && itemType.item_type.items.length > 0 ? (
                            itemType.item_type.items.map((toolItem, toolIndex) => {
                                const checkTotalOrderedQty = !isNaN(toolItem.ordered_qty) ? Math.max(toolItem.ordered_qty || 0, 0) : 0;
                                const checkTotalQty = !isNaN(toolItem.qty) ? Math.max(toolItem.qty || 0, 0) : 0;
                                const price = parseFloat(String(toolItem.price)) || 0;
                                const totalPriceOrdered = price * checkTotalOrderedQty;
                                const totalPriceRemaining = price * (checkTotalQty - checkTotalOrderedQty);
                                const checkTotalPriceRemaining = Math.max(totalPriceRemaining, 0);
                                const unit = toolItem.unit || '';

                                return (
                                    <div key={`${toolKey}-${toolItem.id}`} className="step2-item-type step2-item-type-labor">
                                        <div className="step2-material-width">
                                            <div className="step2-item-checkbox-block">
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox-${toolKey}-${toolItem.id}`}
                                                    checked={selectedItems?.[`${toolKey}-${toolItem.id}`] || false}
                                                    onChange={() => handleCheckboxChange(`${toolKey}-${toolItem.id}`, itemType, toolItem)}
                                                />
                                                <label htmlFor={`checkbox-${toolKey}-${toolItem.id}`}>{toolItem.title}</label>
                                            </div>
                                        </div>
                                        <p className="step2-other-width">{checkTotalOrderedQty} ({unit})</p>
                                        <p className="step2-other-width">{checkTotalQty} ({unit})</p>
                                        <p className="step2-other-width">R {price.toFixed(2)}</p>
                                        <p className="step2-other-width">R {totalPriceOrdered.toFixed(2)}</p>
                                        <p className="step2-other-width">R {checkTotalPriceRemaining.toFixed(2)}</p>
                                        <div className='step2-other-width-vendor'>
                                            {toolItem.vendor ? (
                                                <img
                                                    className='step3-vendor-img'
                                                    src={getVendorLogo(toolItem.vendor  as Vendor)} />
                                            ) : (
                                                <p className='item-selection-modal-no-vendor'>No Vendor</p>
                                            )}                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div key={toolKey} className='step2-item-type'>
                                <div className='step2-material-width'>
                                    <label style={{ color: "#828282" }}>{title}</label>
                                </div>
                                <p className="step2-other-width">0</p>
                                <p className="step2-other-width">{totalQty}</p>
                                <p className="step2-other-width">R 0.00</p>
                                <p className="step2-other-width">R 0.00</p>
                                <p className="step2-other-width">R 0.00</p>
                            </div>
                        )}
                    </div>
                );
            })}

            <div className="step2-bottom-block">
                <p>Total:</p>
                {totalPrice ? <span>R {totalPrice.toFixed(2)}</span> : <span>R 0</span>}
                <button className="step2-bottom-block-btn-cart">Add tool to cart</button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    oldProject: state.projectById.oldProject,
});

const mapDispatchToProps = {
    setSelectedTools,
    clearSelectedItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ToolsItemTypeList);
