import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { fetchAllTemplates } from "../../Slice/getAllTemplates/GetAllTemplatesSlice";
import { RootState } from "../../store";
import { connect, ConnectedProps, useSelector } from "react-redux";
import PaginationAssistance from "../../components/PaginationAssistance/pagination";
import './style.css';
import AssisTemplaeModal from "../../components/Modals/Assis Template Modal/AssisTemplaeModal";
import 'react-toastify/dist/ReactToastify.css';
import { notifyInfo, notifySuccess } from "../../components/Toast/AssisCreateToastNotification";
import { Template } from "../../types/TemplateType";
import { addTemplate, clearTemplates, removeTemplate, removeCalculatedTemplate } from "../../Slice/LocalTemplate/LocalTemplateSlice";
import { transformTemplate } from "../../utils/TransformTemplate";
import DeleteModal from "../../components/Modals/ModalDelete/ModalDelete";
import { clearLocalStorageExceptToken as clearLocalStorageFunction } from "../../utils/ClearLocalStorage";
import { fetchProjectById, updatedProject, clearProject } from "../../Slice/projectById/projectByIdSlice";
import { Loader } from "../../components/Loader/Loader";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";

interface TemplateWithCalculated extends Template {
    isCalculated?: boolean;
}

const AssisCreate: React.FC<PropsFromRedux> = ({
                                                   error,
                                                   isFetching,
                                                   templates,
                                                   totalTemplate,
                                                   fetchAllTemplates,
                                                   fetchProjectById,
                                                   updatedProject,
                                                   addTemplate,
                                                   clearTemplates,
                                                   removeTemplate,
                                                   removeCalculatedTemplate,
                                                   clearProject,
                                               }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const selectedTemplates = useSelector((state: RootState) => state.localTemplate.templates.map(template => ({
        ...template,
        isCalculated: JSON.parse(localStorage.getItem(`selectedTemplate-${template.id}`) || 'false')
    })));
    const existingTemplates = useSelector((state: RootState) => state.projectById.project?.data?.templates || []);
    const [currentTemplatePage, setCurrentTemplatePage] = useState(1);
    const templatesPerPage = 8;
    const [isSelectedTemplatesModalOpen, setIsSelectedTemplatesModalOpen] = useState<boolean>(false);
    const [templateToDelete, setTemplateToDelete] = useState<TemplateWithCalculated | null>(null);

    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const [isSmallScreenButton, setIsSmallScreenButton] = useState<boolean>(window.innerWidth <= 875);

    const { projectId, openModal, from } = location.state || {};

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
        setIsSmallScreenButton(window.innerWidth <= 875);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (openModal) {
            setIsSelectedTemplatesModalOpen(true);
        }
    }, [openModal]);

    const clearLocalStorageExceptTokenHandler = () => {
        navigate('/assistance');
        clearLocalStorageFunction();
        clearTemplates();
        clearProject();
    };

    useEffect(() => {
        if (isSelectedTemplatesModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isSelectedTemplatesModalOpen]);

    useEffect(() => {
        fetchAllTemplates({ page: currentTemplatePage, perPage: templatesPerPage });
    }, [currentTemplatePage, templatesPerPage, fetchAllTemplates]);

    useEffect(() => {
        if (from === 'projectCosts') {
            fetchProjectById({ projectId: projectId });
        } else {
            clearTemplates();
            clearProject();
        }

        return () => {
            clearTemplates();
            clearProject();
        };
    }, [from, projectId, fetchProjectById, clearTemplates, clearProject]);

    const paginateTemplate = (pageNumber: number) => setCurrentTemplatePage(pageNumber);

    const handleTemplateClick = (template: Template) => {
        const isCalculated = JSON.parse(localStorage.getItem(`selectedTemplate-${template.id}`) || 'false');
        if (selectedTemplates.some(t => t.id === template.id)) {
            if (isCalculated) {
                setTemplateToDelete({ ...template, isCalculated });
            } else {
                removeTemplate(template.id);
                localStorage.removeItem(`selectedTemplate-${template.id}`);
                notifyInfo(`Project "${template.title}" has been removed from your project group`);
            }
        } else {
            addTemplate(template);
            notifySuccess(`Project "${template.title}" has been added to your group`);
        }
    };

    const handleSaveSelectedTemplates = () => {
        setIsSelectedTemplatesModalOpen(false);
    };

    const handleRemoveTemplate = (templateId: number) => {
        removeTemplate(templateId);
        removeCalculatedTemplate(templateId);
        localStorage.removeItem(`selectedTemplate-${templateId}`);
        notifyInfo(`Project removed from selection`);
        setTemplateToDelete(null);
    };

    const handleNextStepClick = () => {
        if (selectedTemplates.length === 0) {
            notifyInfo("Please select at least one project to proceed.");
            return;
        }

        let allTemplates: Template[];
        if (existingTemplates.length > 0) {
            allTemplates = [...existingTemplates, ...selectedTemplates];
        } else {
            allTemplates = selectedTemplates;
        }

        const transformedTemplates = allTemplates.map(template => transformTemplate(template));
        const updatedProjectData = {
            data: {
                templates: transformedTemplates
            }
        };

        updatedProject({ projectId, projectData: updatedProjectData })
            .then(() => {
                if (existingTemplates.length === 0 && selectedTemplates.length === 1) {
                    localStorage.setItem(`selectedTemplate-${selectedTemplates[0].id}`, JSON.stringify(selectedTemplates[0]));
                    navigate(`/assistance/step1/${selectedTemplates[0].id}`, {
                        state: {
                            projectId,
                            template: selectedTemplates[0],
                            templIndex: 0
                        }
                    });
                } else {
                    clearLocalStorageFunction();
                    clearTemplates();
                    clearProject();
                    navigate(`/costs/${projectId}`, { state: { projectId } });
                }
            })
            .catch((err) => {
                console.error("Failed to update project:", err);
                notifyInfo("Failed to update project. Please try again.");
            });
    };

    const cancelResetTemplates = () => {
        setTemplateToDelete(null);
    };

    const isTemplateSelected = (template: Template) => {
        return selectedTemplates.some(st => st.id === template.id) || (from === 'projectCosts' && existingTemplates.some(et => et.id === template.id));
    };

    if (isFetching) return <Loader />;
    if (error) return <p>Error: {error}</p>;

    const groupedTemplates = groupByCategory(templates);

    return (
        <>
            <div className='template-name-content'>
                {isSmallScreen ? (
                    <MobileNavigation
                        links={[
                            { to: '/', label: 'Cashbuild' },
                            { to: '', label: '>' },
                            { to: '/', label: 'Home' },
                            { to: '', label: '>' },
                            { to: '/assistance', label: 'Project assistance' },
                            { to: '', label: '>' },
                            { to: '/assistance/create', label: 'Create new project space' }
                        ]}
                    />
                ) : (
                    <div className='template-blog-placeholder'>
                        <p>Home/</p>
                        <p onClick={clearLocalStorageExceptTokenHandler} className='template-link-assistance'>Project group assistance</p>
                        <p className='name-link'>/Create new project space</p>
                    </div>
                )}
            </div>
            <div className='step-info'>
                <div className='step-info-block'>
                    <p>Step 1</p>
                    <span>/</span>
                    <p className='step-info-block-name'>Choose projects</p>
                </div>
                <div className='step-info-btn-mobile'>
                    <button className='step-info-btn' onClick={() => setIsSelectedTemplatesModalOpen(true)}>
                        View Selected
                        {!isSmallScreenButton && (
                            <>
                                <br />
                                projects
                            </>
                        )}
                    </button>

                    {isSmallScreenButton && (
                        <button
                            className={`assis-bottom-btn ${selectedTemplates.length === 0 ? 'assis-btn-block' : ''}`}
                            onClick={handleNextStepClick}
                            disabled={selectedTemplates.length === 0}
                        >
                            Next step
                        </button>
                    )}
                </div>
            </div>
            <div className='template-main'>
                {Object.keys(groupedTemplates).map(category => (
                    <div key={category}>
                        {category !== 'Others' && <h2>{category}</h2>}
                        <div className='template-main-container'>
                            {groupedTemplates[category].map((template) => {
                                const templateImage = template.galleries && template.galleries.length > 0 && template.galleries[0].medias.length > 0
                                    ? template.galleries[0].medias[template.galleries[0].medias.length - 1].url
                                    : '/img/house.jpg';
                                return (
                                    <div
                                        className={`template-project-list ${isTemplateSelected(template) ? 'selected' : ''}`}
                                        key={template.id}
                                        onClick={() => handleTemplateClick(template)}
                                    >
                                        <div className='template-project-get'>
                                            <div className='template-img-shadow' style={{
                                                backgroundImage: `url(${templateImage})`
                                            }}>
                                                {isTemplateSelected(template) &&
                                                    <div className="checkmark">&#10003;</div>}
                                            </div>
                                            <div className='template-title-block'>
                                                <p className='template-title'>{template.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ))}
                {(!isSmallScreenButton || totalTemplate <= templatesPerPage) && (
                    <div
                        className='assis-bottom-block'
                        style={{ marginBottom: totalTemplate <= templatesPerPage ? '20px' : '0' }}
                    >
                        <button
                            className={`assis-bottom-btn ${selectedTemplates.length === 0 ? 'assis-btn-block' : ''}`}
                            onClick={handleNextStepClick}
                            disabled={selectedTemplates.length === 0}
                        >
                            Next step
                        </button>
                    </div>
                )}
            </div>
            {totalTemplate > templatesPerPage && (
                <PaginationAssistance
                    projectsPerPage={templatesPerPage}
                    totalProjects={totalTemplate}
                    paginate={paginateTemplate}
                    currentPage={currentTemplatePage}
                />
            )}
            {isSelectedTemplatesModalOpen && (
                <AssisTemplaeModal
                    projectId={projectId}
                    isOpen={isSelectedTemplatesModalOpen}
                    onClose={() => setIsSelectedTemplatesModalOpen(false)}
                    templates={selectedTemplates}
                    onRemoveTemplate={handleRemoveTemplate}
                    onSave={handleSaveSelectedTemplates}
                />
            )}
            {templateToDelete && (
                <DeleteModal
                    isOpen={true}
                    onRequestClose={() => setTemplateToDelete(null)}
                    onConfirm={() => handleRemoveTemplate(templateToDelete.id)}
                    onCancel={cancelResetTemplates}
                    message={`Are you sure you want to remove the project "${templateToDelete.title}"?`}
                    confirmText="Yes"
                    cancelText="No"
                />
            )}
        </>
    )
};

const mapStateToProps = (state: RootState) => ({
    templates: state.allTemplates.templates,
    totalTemplate: state.allTemplates.totalTemplate,
    isFetching: state.allTemplates.isFetching,
    error: state.allTemplates.error,
});

const mapDispatchToProps = {
    fetchAllTemplates,
    fetchProjectById,
    updatedProject,
    clearTemplates,
    addTemplate,
    removeTemplate,
    removeCalculatedTemplate,
    clearProject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AssisCreate);

const groupByCategory = (templates: Template[]) => {
    const categories: { [key: string]: Template[] } = {
        Others: []
    };
    templates.forEach(template => {
        if (template.categories.length === 0) {
            categories.Others.push(template);
        } else {
            template.categories.forEach(category => {
                if (!categories[category.category]) {
                    categories[category.category] = [];
                }
                categories[category.category].push(template);
            });
        }
    });

    return categories;
};
