export enum Vendor {
    Cashbuild = 'cashbuild',
    Afrisam = 'afrisam',
    LeroyMerlin = 'leroy merlin',
}
export const vendorLogos: Record<Vendor, string> = {
    [Vendor.Cashbuild]: '/img_vendor/cashbuild.svg',
    [Vendor.Afrisam]: '/img_vendor/afrisam.svg',
    [Vendor.LeroyMerlin]: '/img_vendor/LeroyMerlin.svg',
};

export const getVendorLogo = (vendor: Vendor): string => {
    return vendorLogos[vendor];
};